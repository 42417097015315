export const useMedicalRegister = {
  watch: {
    selectedWs: {
      handler (data) {
        if (!data || Object.keys(data).length === 0) {
          window.$toast.open({
            message: 'Không tìm thấy thông tin Khoa, vui lòng chọn lại',
            type: 'warning'
          })
          this.$router.replace({
            name: 'ExaminationRegister'
          })
        }
      },
      deep: true,
      immediate: true
    }
  }
}
