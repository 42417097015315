<template>
  <div class="container flex flex-col flex-1">
    <div class="text-center w-full lg:w-3/5 m-auto" v-if="!isNextStep">
      <div class="bg-pri-900 text-white font-bold py-1">Thông Báo</div>
      <div class="border py-3 px-2 px-md-3 text-xl">
        <div>Bạn chưa có thông tin trên hệ thống.</div>
        <div>Vui lòng nhập thông tin để có thể đặt lịch khám.</div>
        <div class="mt-3">
          <b-button variant="outline-primary" @click="handleNextStep">Tiếp tục</b-button>
        </div>
      </div>
    </div>
    <template v-else>
      <b-overlay :show="loading" :opacity="0.85" variant="white" spinner-variant="primary" rounded="sm" blur="none"
        class="flex flex-col flex-1" overlay-tag="div">
        <validation-observer ref="updateUserform" tag="div">
          <h1 class="mt-3 mt-md-5 mb-3 text-center text-pri-900">
            Thông tin hành chính
          </h1>

          <div v-if="persons.length > 0">
            <b-alert show variant="info" class="text-center">
              <div>Thông tin của bạn đã có trên hệ thống của chúng tôi.</div>
              <div>Xin vui lòng kiểm tra và xác nhận lại.</div>
            </b-alert>
          </div>
          <div class="mb-4">
            <b-card title="Người Bệnh" class="product-add-wrapper mb-2">
              <!-- User Info: Input Fields -->
              <b-form>
                <b-row>
                  <b-col v-if="persons.length > 1" cols="12" md="12" class="mb-2">
                    <b-form-group label-for="address">
                      <label for="text-password">Danh sách người thân</label>
                      <v-select v-model="selectedPerson" dense label="name" :options="persons" :clearable="false"
                        id="address" @input="onChangePerson" />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Full Name -->
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-group label-for="fullname">
                      <validation-provider #default="{ errors }" name="First Name" rules="required">
                        <label for="text-password">
                          Họ và tên
                          <span class="text-danger">*</span>
                        </label>
                        <div class="flex">
                          <b-form-input id="fullname" v-model="userData.fullName" />
                        </div>
                        <small v-if="errors[0]" class="text-danger">Nhập họ và tên</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Phone -->
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-group label-for="phone">
                      <validation-provider #default="{ errors }" name="Phone" rules="required">
                        <label for="text-password">
                          Số điện thoại
                          <span class="text-danger">*</span>
                        </label>
                        <b-form-input id="phone" type="number" v-model="userData.phone" />
                        <small v-if="errors[0]" class="text-danger">Nhập số điện thoại</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Date of birth -->
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-group label-for="email">
                      <validation-provider #default="{ errors }" name="dateOfBirth" rules="required">
                        <label for="text-password">
                          Ngày sinh
                          <span class="text-danger">*</span>
                        </label>
                        <b-form-input pattern="\d{4}-\d{2}-\d{2}" type="date" v-model="userData.dateOfBirth" />
                        <small v-if="errors[0]" class="text-danger">Nhập ngày sinh</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: Gender -->
                  <b-col class="mt-1 mb-2" cols="12" md="6">
                    <b-form-group class="mt-1">
                      <validation-provider #default="{ errors }" name="Gender" rules="required">
                        <label for="text-password">
                          Giới tính
                          <span class="text-danger">*</span>
                        </label>
                        <b-row>
                          <b-col>
                            <b-form-radio value="1" v-model="userData.gender">
                              <span class="ml-2">Nam</span>
                            </b-form-radio>
                          </b-col>
                          <b-col>
                            <b-form-radio value="2" v-model="userData.gender">
                              <span class="ml-2">Nữ</span>
                            </b-form-radio>
                          </b-col>
                        </b-row>
                        <small v-if="errors[0]" class="text-danger">Chọn giới tính</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: CCCD -->
                  <b-col cols="12" md="12" class="mb-2">
                    <b-form-group label-for="idCard">
                      <validation-provider #default="{ errors }" name="idCard" rules="required">
                        <label for="idCard">
                          Căn cước công dân (CCCD/ID)
                          <span class="text-danger">*</span>
                        </label>
                        <b-form-input id="idCard" v-model="userData.idCard" />
                        <small v-if="errors[0]" class="text-danger">Nhập căn cước công dân</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: BHYT -->
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-group label-for="bhytCode">
                      <label for="text-password">Mã số Bảo hiểm Y tế (VSSID) (nếu có)</label>
                      <b-form-input id="bhytCode" v-model="userData.insurance_number" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-group>
                      <label for="text-password">Ngày hết hạn BHYT</label>
                      <b-form-input pattern="\d{4}-\d{2}-\d{2}" type="date" v-model="userData.insurance_expire" />
                    </b-form-group>
                  </b-col>
                  <!-- Field: Emergency Contact -->
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-group label-for="emergencyContact">
                      <label for="text-password">Họ tên, địa chỉ người nhà khi cần báo tin</label>
                      <b-form-input id="emergencyContact" v-model="userData.emergency_contact" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-group>
                      <label for="text-password">Số điện thoại liên hệ</label>
                      <b-form-input id="contact_phone" type="number" v-model="userData.contact_phone" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
            <b-card class="product-add-wrapper mb-2">
              <h4 class="mb-2" @click="handleToggleShowContactInfo">
                Thông tin liên hệ
                <img class="transition" :style="{ transform: `rotate(${deg}deg)` }"
                  src="../../../../public/icons/arrow.svg" />
              </h4>
              <!-- User Info: Input Fields -->
              <b-form v-if="isShowContactInfo">
                <b-row>
                  <!-- Field: Address -->
                  <b-col cols="12" md="12" class="mb-2">
                    <b-form-group label-for="address">
                      <label for="text-password">Địa chỉ liên hệ</label>
                      <b-form-input id="address" v-model="userData.address" />
                    </b-form-group>
                  </b-col>
                  <!-- Field: City -->
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-group label-for="address-city">
                      <label for="text-password">Tỉnh/thành phố</label>
                      <b-form-input id="address-city" v-model="userData.city" />
                      <!-- <v-select
                      v-model="userData.city"
                      :options="cityOptions"
                      :clearable="false"
                      input-id="region"
                      @input="mapdistrictOptions(userData.city)"
                      />-->
                    </b-form-group>
                  </b-col>
                  <!-- Field: district -->
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-group label-for="address-district">
                      <label for="text-password">Quận/huyện</label>
                      <b-form-input id="address-district" v-model="userData.district" />
                      <!-- <v-select
                      v-model="userData.district"
                      :options="provinOptions"
                      :clearable="false"
                      input-id="region"
                      @input="mapWardsOptions(userData.district)"
                      />-->
                    </b-form-group>
                  </b-col>
                  <!-- Field: Ward -->
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-group label-for="address-ward">
                      <label for="text-password">Xã/phường</label>
                      <b-form-input id="address-ward" v-model="userData.ward" />
                      <!-- <v-select
                      v-model="userData.ward"
                      :options="wardsOptions"
                      :clearable="false"
                      input-id="region"
                      />-->
                    </b-form-group>
                  </b-col>
                  <!-- Field: Country -->
                  <b-col cols="12" md="6" class="mb-2">
                    <b-form-group label-for="address-country">
                      <label for="text-password">Quốc tịch</label>
                      <v-select dense label="name" :options="listCountry" :clearable="false" id="address-country"
                        v-model="userData.country" @input="handleSelectedCountry" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
            <b-row class="mb-2">
              <b-col cols="12" md="6" class="mb-2">
                <b-button @click="handleCancel"
                  style="width: 100%; color: #20419b; background-color: #f2f4f7">Hủy</b-button>
              </b-col>
              <b-col cols="12" md="6" class="mb-2">
                <b-button @click="validateForm" style="width: 100%; background-color: #20419b">{{
                  persons.length > 0 ? "Xác nhận thông tin" : "Lưu thông tin"
                }}</b-button>
              </b-col>
            </b-row>
          </div>
        </validation-observer>
      </b-overlay>
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  BButton,
  BCard,
  BCol,
  BForm,
  // BButton,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BOverlay
  // BFormDatepicker
  ,

  BRow
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapGetters } from 'vuex'
import {
  isPhoneNumber,
  required
} from '../../../../src/utils/validations/validations'
// import OtpInput from '@bachdgvn/vue-otp-input'
import { useMedicalRegister } from '@/utils/mixinMedicalRegister'
import axios from 'axios'
import moment from 'moment'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import appUtils from '../../../utils/appUtils'

export default {
  name: 'MedicalRegisterForm',
  components: {
    vSelect,
    // OtpInput,
    // BButton,
    // BFormDatepicker,
    BFormRadio,
    BCard,
    BFormGroup,
    BRow,
    BFormInput,
    BForm,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BOverlay
  },
  mixins: [useMedicalRegister],
  data () {
    return {
      selectedPerson: null,
      persons: [],
      locationData: [],
      cityOptions: [],
      provinOptions: [],
      wardsOptions: [],
      userData: {},
      cityDetect: {},
      districtDetect: {},
      dial_code: 84,
      isPhoneNumber,
      required,
      fullName: null,
      phone: null,
      time_out: 0,
      step: 1,
      loading: false,
      otpCount: 0,
      user_id: null,
      deg: 0,
      isShowContactInfo: true,
      isNextStep: false,
      selectedCountry: {}
    }
  },
  // created () {
  //   if (Object.keys(this.userStateData).length) {
  //     this.$router
  //       .replace({
  //         name: 'ReviewFormInformation'
  //       })
  //       .catch(() => {})
  //   }
  // },
  computed: {
    ...mapGetters('registerMedical', [
      'listCountry',
      'defaultWs',
      'selectedWs',
      'userStateData'
    ])
  },
  async beforeMount () {
    this.userData = appUtils.getLocalUser()
    // this.getDataDistrict()
    if (this.userData.person !== null) {
      this.isShowContactInfo = true
      this.deg = 0
      const user = appUtils.getLocalUser()
      this.userData = {
        ...user.person,
        id: user.person.id,
        fullName: user.person.name,
        phone: user.person.phone,
        dateOfBirth: user.person.birthday,
        gender: user.person.gender,
        idCard: user.person.cmnd,
        address: user.person.address,
        city: user.person.address_province_name,
        district: user.person.address_district_name,
        ward: user.person.address_wards_name,
        insurance_number: user.person.insurance_number,
        insurance_expire: user.person.insurance_expire,
        country: {
          code: user.person?.national_id,
          name: user.person?.nationality_name
        }
      }
      this.isNextStep = true
    } else {
      this.userData.phone = this.$route.query.phone
      this.userData.fullName = this.$route.query.fullName
    }

    await this.getPersons()
  },
  methods: {
    validateForm () {
      this.$refs.updateUserform.validate().then((success) => {
        if (success) {
          this.loading = true
          if (this.userData.person !== null || this.persons?.length > 0) {
            console.log('update user')
            this.updateUser()
          } else {
            console.log('create user')
            this.createUser()
          }
        }
      })
    },
    onChangePerson () {
      this.userData = {
        id: this.selectedPerson.id,
        fullName: this.selectedPerson.name,
        phone: this.selectedPerson.phone,
        dateOfBirth: this.selectedPerson.birthday,
        gender: this.selectedPerson.gender,
        idCard: this.selectedPerson.cmnd,
        address: this.selectedPerson.address,
        city: this.selectedPerson.address_province_name,
        district: this.selectedPerson.address_district_name,
        ward: this.selectedPerson.address_wards_name,
        insurance_number: this.selectedPerson.insurance_number,
        insurance_expire: this.selectedPerson.insurance_expire,
        country: {
          code: this.selectedPerson?.national_id,
          name: this.selectedPerson?.nationality_name
        }
      }
    },
    handleNextStep () {
      this.loading = true
      this.isNextStep = true
      setTimeout(() => {
        if (!this.persons) return
        this.loading = false
      }, 500)
    },
    async getPersons () {
      const params = {
        limit: 100
      }

      this.loading = true

      await this.$rf
        .getRequest('AuthRequest')
        .getPersons(params)
        .then((resp) => {
          if (resp.data) {
            console.log(resp.data.data)
            this.persons = resp.data.data

            if (this.persons?.length === 0) {
              this.isNextStep = false
            } else {
              this.isNextStep = true
            }

            if (this.$route.query?.pId) {
              this.selectedPerson = this.persons.find(
                (x) => x.id === parseInt(this.$route.query?.pId)
              )
              this.userData = {
                id: this.selectedPerson.id,
                fullName: this.selectedPerson.name,
                phone: this.selectedPerson.phone,
                dateOfBirth: this.selectedPerson.birthday,
                gender: this.selectedPerson.gender,
                idCard: this.selectedPerson.cmnd,
                address: this.selectedPerson.address,
                city: this.selectedPerson.address_province_name,
                district: this.selectedPerson.address_district_name,
                ward: this.selectedPerson.address_wards_name,
                insurance_number: this.selectedPerson.insurance_number,
                insurance_expire: this.selectedPerson.insurance_expire,
                country: {
                  code: this.selectedPerson?.national_id,
                  name: this.selectedPerson?.nationality_name
                }
              }
            }
          }
        })
        .catch((e) => {
          console.log(e)
          this.loading = false
          window.$toast.open({
            message: 'Lỗi lấy thông tin danh sách người thân',
            type: 'warning'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async updateUser () {
      const wsid =
        parseInt(this.$route.query.wsid) || parseInt(this.selectedWs?.id)
      const params = {
        name: this.userData.fullName,
        phone: this.userData.phone,
        birthday: this.userData.dateOfBirth, // this.moment(this.userData.dateOfBirth).format('YYYY-MM-DD'),
        gender: this.userData.gender,
        cmnd: this.userData.idCard,
        address: this.userData.address,
        address_district_name: this.userData.district,
        address_province_name: this.userData.city,
        address_wards_name: this.userData.ward,
        clinic_id: wsid,
        insurance_number: this.userData.insurance_number,
        insurance_expire: this.userData.insurance_expire,
        national_id: this.userData?.country?.code,
        nationality_name: this.userData?.country?.name,
        emergency_contact: this.userData?.emergency_contact,
        contact_phone: this.userData?.contact_phone
      }
      console.log(params)
      await this.$rf
        .getRequest('AuthRequest')
        .updatePersonV2(this.userData.id, params)
        .then((resp) => {
          this.getInfo()
          if (resp.data) {
            this.sendAmplitude('Click to save profile ', {
              'Person ID': resp.data.user_id,
              Status: 'Success',
              Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY'),
              'Person Name': resp.data.name
            })
          }
        })
        .catch((e) => {
          this.sendAmplitude('Click to save profile ', {
            Status: 'Fail',
            Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY'),
            Error: e
          })
        })
        .finally(() => {
          this.loading = false
          window.$toast.open({
            message:
              this.persons?.length > 0
                ? 'Bạn đã xác minh thành công'
                : 'Đã lưu thông tin thành công',
            type: 'success'
          })
          this.$router.push({
            name: 'ReviewFormInformation',
            query: {
              pId: this.userData?.id
            }
          })
          // setTimeout(() => {
          //   self.$router.push({ path: '/hssk' })
          // }, 100)
        })
    },
    async createUser () {
      const wsid =
        parseInt(this.$route.query.wsid) || parseInt(this.selectedWs?.id)
      const params = {
        user_id: this.userData.id,
        name: this.userData.fullName,
        phone: this.userData.phone,
        birthday: this.userData.dateOfBirth,
        gender: Number(this.userData.gender),
        cmnd: this.userData.idCard,
        address: this.userData.address,
        address_district_name: this.userData.district,
        address_province_name: this.userData.city,
        address_wards_name: this.userData.ward,
        clinic_id: wsid,
        insurance_number: this.userData.insurance_number,
        insurance_expire: this.userData.insurance_expire,
        national_id: this.userData?.country?.code,
        nationality_name: this.userData?.country?.name
      }
      await this.$rf
        .getRequest('AuthRequest')
        .createPersonV2(params)
        .then((resp) => {
          this.getInfo()
          if (resp.data) {
            this.sendAmplitude('Click to save profile ', {
              'Person ID': resp.data.user_id,
              Status: 'Success',
              Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY'),
              'Person Name': resp.data.name
            })
          }
        })
        .catch((e) => {
          this.sendAmplitude('Click to save profile ', {
            Status: 'Fail',
            Timestamp: moment(new Date()).format('HH:mm DD-MM-YYYY'),
            Error: e
          })
        })
        .finally(() => {
          this.loading = false
          window.$toast.open({
            message:
              this.persons?.length > 0
                ? 'Bạn đã xác minh thành công'
                : 'Đã lưu thông tin thành công',
            type: 'success'
          })
          this.$router.push({
            path: '/review-form-information'
          })
          // setTimeout(() => {
          //   self.$router.push({ path: '/hssk' })
          // }, 100)
        })
    },
    async getInfo () {
      const self = this
      await self.$rf
        .getRequest('AuthRequest')
        .me()
        .then((rs) => {
          console.log(rs.data)
          if (rs.data?.person) {
            this.isNextStep = true
          }
          appUtils.setLocalUser(rs.data)
        })
        .finally(() => {
          console.log('Cập nhật thông tin thành công')
        })
    },
    formatDMY (d) {
      console.log(this.moment(d).format('DD/MM/YYYY'))
      return this.moment(d).format('DD/MM/YYYY')
    },
    async getDataDistrict (txnId) {
      const res = await axios({
        url: 'https://districts.open-api.vn/api/?depth=3',
        method: 'GET'
      })
      this.locationData = res.data
      console.log(this.locationData)
      this.cityOptions = res.data.map((x) => x.name)
    },
    mapdistrictOptions (cityName) {
      this.provinOptions = []
      this.wardsOptions = []
      this.cityDetect = this.locationData.find((x) => x.name === cityName)
      this.provinOptions = this.cityDetect.districts.map((x) => x.name)
    },
    mapWardsOptions (districtName) {
      this.wardsOptions = []
      this.districtDetect = this.cityDetect.districts.find(
        (x) => x.name === districtName
      )
      this.wardsOptions = this.districtDetect.wards.map((x) => x.name)
    },
    handleToggleShowContactInfo () {
      this.isShowContactInfo = !this.isShowContactInfo
      this.deg = this.isShowContactInfo ? 0 : -90
    },
    handleSelectedCountry (value) {
      // console.log(value, this.userData.country)
    },
    handleCancel () {
      // appUtils.removeLocalToken()
      // appUtils.removeLocalUser()
      this.$store.commit('registerMedical/setSelectedWs', {})
      this.$router
        .replace({
          name: 'ExaminationRegister'
        })
        .catch(() => { })
    }
  }
}
</script>
<style scoped>
.form {
  justify-content: center;
  display: flex;
  align-items: center;
  min-height: 100vh;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.transition {
  width: 18px;
  transition: transform 0.1s ease-in-out;
}
</style>
